import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { FaBuilding, FaMapMarkerAlt, FaSearch } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import PhoneInput from "react-phone-input-2"; // Import PhoneInput

const CareerList = () => {
  const [servicesList, setServicesList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    qualification: "",
    job: "",
    resume: null,
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    qualification: "",
    mobile: "",
  });

  const nameRegex = /^[A-Za-z\s]+$/;
  const qualificationRegex = /^[A-Za-z\s]+$/;
  const phoneRegex = /^[+]*[0-9]{1,4}[-\s\./0-9]*$/;

  const fetchJobs = async (query = "") => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5009/choiceglobal/list/jobs",
        { search: query }
      );
      if (response.data.result === true) {
        setServicesList(response.data.list);
      } else {
        setServicesList([]);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  // Fetch jobs on initial render
  useEffect(() => {
    fetchJobs();
  }, []);

  // Debounce function to delay API calls
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Search handler with debounce
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedFetchJobs(query);
  };

  // Debounced fetchJobs
  const debouncedFetchJobs = debounce(fetchJobs, 500);

  const handleApplyClick = (jobName, jobId) => {
    setFormData({ ...formData, job: jobName, jobId: jobId });
    setSelectedJob(jobName);
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleResumeUpload = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  // Phone change handler for PhoneInput
  const handlePhoneChange = (value) => {
    setFormData({ ...formData, mobile: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setFormErrors({
      name: "",
      qualification: "",
      mobile: "",
    });

    // Validate form fields
    let isValid = true;
    let errors = {};

    if (!nameRegex.test(formData.name)) {
      isValid = false;
      errors.name = "Name should only contain letters and spaces.";
    }

    if (!qualificationRegex.test(formData.qualification)) {
      isValid = false;
      errors.qualification =
        "Qualification should only contain letters and spaces.";
    }

    if (!phoneRegex.test(formData.mobile)) {
      isValid = false;
      errors.mobile = "Please enter a valid phone number.";
    }

    if (!isValid) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }

    const submitData = new FormData();
    submitData.append("name", formData.name);
    submitData.append("email", formData.email);
    submitData.append("mobile", formData.mobile);
    submitData.append("qualification", formData.qualification);
    submitData.append("job_name", formData.job);
    submitData.append("job_id", formData.jobId);
    submitData.append("image", formData.resume);

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5009/choiceglobal/apply/job",
        submitData
      );
      if (response.data.result) {
        alert("Application submitted successfully!");
        setShowModal(false);
      } else {
        alert("Error submitting application.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const [selectedJobDetails, setSelectedJobDetails] = useState(null); // State for job details
  const [viewModal, setViewModal] = useState(false); // State for the view modal

  const handleViewDetails = async (jobId) => {
    try {
      // Fetch job details using the selected job ID
      const viewdetails = {
        job_id: jobId,
      };
      const response = await axios.post(
        "https://lunarsenterprises.com:5009/choiceglobal/list/jobs",
        viewdetails
      );
      if (response.data.result === true) {
        // Set the fetched job details to the state
        setSelectedJobDetails(response.data.list[0]); // Assuming response.data.list contains the job details
        setViewModal(true); // Open the modal
      } else {
        setSelectedJobDetails(null); // Clear the job details if no results
        setViewModal(false); // Close the modal
      }
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  };

  return (
    <div className="sectionpage">
      <div className="container">
        <div className="d-flex align-items-center mb-5">
          <h1 className="Headtitle">JOB OPPORTUNITIES</h1>
        </div>

        {/* Search bar */}
        {/* <div className="mb-4"> */}
        <div class="container h-100">
          <div class="d-flex justify-content-center h-100">
            <div class="search">
              <input
                class="search_input"
                type="text"
                name=""
                placeholder="Search for a job"
                value={searchQuery}
                onChange={handleSearch}
              />
              <a href="#" class="search_icon">
                <i class="fa fa-search">
                  <FaSearch />
                </i>
              </a>
            </div>
          </div>
              
        </div>
        {/* <Form.Control
            type="text"
            placeholder="Search for a job..."
            value={searchQuery}
            onChange={handleSearch}
          /> */}
        {/* </div> */}

        <div className="solution-itemgrid">
          {servicesList.length > 0 ? (
            servicesList.map((course) => (
              <div className="solution-itemgridsec bounce" key={course.id}>
                <div className="imgDiv-carousel"></div>
                <div className="slideSecondcate">
                  <h5 className="mt-2">{course.jb_name}</h5>
                  <p className="cebttop">
                    Closing on{" "}
                    {moment(course.jb_closing_date).format("DD MMM YYYY")}
                  </p>
                  <p className="cebttop">
                    <FaMapMarkerAlt /> {course.jb_location}
                  </p>
                  <p className="cebttop">
                    <FaBuilding /> {course.jb_type}
                  </p>
                  <div className="d-flex  align-items-center">
                    <Button
                      className="mt-2 me-2 buttoncustmapply mb-2"
                      onClick={() =>
                        handleApplyClick(course.jb_name, course.jb_id)
                      }
                    >
                      Apply Now
                    </Button>

                    <Button
                      className="mt-2 buttoncustmapply mb-2"
                      onClick={() => handleViewDetails(course.jb_id)}
                    >
                      View Details
                    </Button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No jobs found matching your search criteria.</p>
          )}
        </div>
      </div>

      {/* Apply Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Apply for {selectedJob}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              {formErrors.name && (
                <Alert variant="danger" className="mt-2">
                  {formErrors.name}
                </Alert>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formMobile">
              <Form.Label>Mobile</Form.Label>
              <PhoneInput
                country={"om"}
                value={formData.mobile}
                onChange={handlePhoneChange}
                inputStyle={{ height: "40px" }}
                inputClass="form-control inputstylejob"
                required
              />
              {formErrors.mobile && (
                <Alert variant="danger" className="mt-2">
                  {formErrors.mobile}
                </Alert>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formQualification">
              <Form.Label>Qualification</Form.Label>
              <Form.Control
                type="text"
                name="qualification"
                value={formData.qualification}
                onChange={handleInputChange}
                required
              />
              {formErrors.qualification && (
                <Alert variant="danger" className="mt-2">
                  {formErrors.qualification}
                </Alert>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formResume">
              <Form.Label>Resume</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={handleResumeUpload}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              className="buttoncustmapply"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <ClipLoader color="#fff" size={20} />
              ) : (
                "Submit Application"
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* View Job Details Modal */}
      <Modal show={viewModal} onHide={() => setViewModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Job Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedJobDetails && (
            <>
              <h5>{selectedJobDetails.jb_name}</h5>
              <p>
                <strong>Location:</strong> {selectedJobDetails.jb_location}
              </p>
              <p>
                <strong>Type:</strong> {selectedJobDetails.jb_type}
              </p>
              <p>
                <strong>Status:</strong> {selectedJobDetails.jb_status}
              </p>
              <p>
                <strong>Posted At:</strong>{" "}
                {moment(selectedJobDetails.jb_posted_at).format("DD MMM YYYY")}
              </p>
              <p>
                <strong>Closing Date:</strong>{" "}
                {moment(selectedJobDetails.jb_closing_date).format(
                  "DD MMM YYYY"
                )}
              </p>
              <p>
                <strong>Experience:</strong> {selectedJobDetails.jb_experience}
              </p>
              <p>
                <strong>Description:</strong>{" "}
                {selectedJobDetails.jb_description}
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CareerList;
